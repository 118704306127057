/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect} from 'react';
import {shoeSizes, placeOfRegistration, cities, storesInCities} from '../constants';
import 
{TextField, MenuItem, Grid} from "@mui/material";
import _ from 'lodash';
import styles from './NikeComponent.module.css';

function NikeComponent({nikeData, setNikeData, setDisableSubmit, disableSubmit}) {
    useEffect(() =>{
        setNikeData({...nikeData, "shoeSize" : shoeSizes[0].value, "instagramId" : '',"storePersonName" : ''});
        setDisableSubmit({...disableSubmit, "NikeComponent" : true})
    }, [])

    useEffect(()=>{
        setDisableSubmit({ ...disableSubmit, "NikeComponent" : !(nikeData.city && nikeData.placeOfRegistration)})
    }, [nikeData.city, nikeData.placeOfRegistration])

    function handleOnChange(e) {
        setNikeData({ ...nikeData, [e.target.name] : e.target.value });
    }

  return (
    <Grid container spacing={5}>
        {renderInstagramId()}
        {renderShoeSize()}
        {renderPlaceOfRegistration()}
        {renderCity()}

        {
            (nikeData.placeOfRegistration === 'Store')
            ?   <>
                {renderFavStore()}
                {renderStorePersonName()}
                </>
            : undefined
        }

    </Grid>
  )

    function renderStorePersonName(){
        return <Grid xs={12} item key="storePersonName"><TextField
        key="storePersonName"
        name="storePersonName"
        type="text"
        value={_.get(nikeData, 'storePersonName', '')}
        style={{ width: "80%", textAlign: "left" }}
        label="Store Person Name"
        onChange={handleOnChange}>
    </TextField></Grid>;
    }

    function renderFavStore() {
        const array = _.get(storesInCities, nikeData.city, [])
        return <Grid xs={12} item key="favStore"><TextField
            name="favStore"
            key="favStore"
            value={_.get(nikeData, 'favStore')}
            style={{ width: "80%", textAlign: "left" }}
            type="select"
            label="Fav store/Nearest store"
            select={true}
            onChange={handleOnChange}
        >
            {array.map((option) => (
                <MenuItem
                    key={option}
                    value={option}>
                    {option}
                </MenuItem>
            ))}
        </TextField></Grid>;
    }

    function renderCity() {
        return <Grid xs={12} item key="city"><TextField
            name="city"
            key="city"
            value={_.get(nikeData, 'city')}
            style={{ width: "80%", textAlign: "left" }}
            type="select"
            label="City*"
            select={true}
            onChange={handleOnChange}
        >
            {cities.map((option) => (
                <MenuItem
                    key={option}
                    value={option}>
                    {option}
                </MenuItem>
            ))}
        </TextField></Grid>;
    }

    function renderPlaceOfRegistration() {
        return <Grid xs={12} item key="placeOfRegistration"><TextField
            name="placeOfRegistration"
            key="placeOfRegistration"
            value={_.get(nikeData, 'placeOfRegistration')}
            style={{ width: "80%", textAlign: "left" }}
            type="select"
            label="Place Of Registration*"
            select={true}
            onChange={handleOnChange}
        >
            {placeOfRegistration.map((option) => (
                <MenuItem
                    key={option}
                    value={option}>
                    {option}
                </MenuItem>
            ))}
        </TextField></Grid>;
    }

    function renderInstagramId() {
        return <Grid xs={12} item key="instagramId"><TextField
            key="instagramId"
            name="instagramId"
            type="text"
            value={_.get(nikeData, 'instagramId', '')}
            style={{ width: "80%", textAlign: "left" }}
            label="Instagram ID"
            onChange={handleOnChange}>
        </TextField></Grid>;
    }

    function renderShoeSize() {
        return <Grid xs={12} item key="shoeSize"><TextField
            name="shoeSize"
            key="shoeSize"
            value={_.get(nikeData, 'shoeSize')}
            style={{ width: "80%", textAlign: "left" }}
            type="select"
            label="Shoe Size"
            select={true}
            onChange={handleOnChange}
        >
            <div className={styles.sizeChartContainer}>
                <span>Size-UK/India</span>
                <a className={styles.linkBtn} href={`${process.env.PUBLIC_URL}/size-chart.pdf`} download>Size Chart</a>
            </div>

            {shoeSizes.map((option) => (
                <MenuItem
                    key={option.value}
                    value={option.value}>
                    {option.label}
                </MenuItem>
            ))}
        </TextField></Grid>;
    }
}

export default NikeComponent


