/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react'
import {
    MenuItem,
    TextField
  } from "@mui/material";
import _ from 'lodash';

function SelectField({field, pwa, handleError, handleChange, selectFieldArray}) {
  const valueOrDefault = _.get(pwa, field.name);

  return (
    <TextField
    name={field.name}
    key={field.name}
    value={ valueOrDefault}
    style={{ width: "80%", textAlign: "left" }}
    type="select"
    label={field.mandatory === true
      ? `${field.label}*`
      : field.label}
    select={field.fieldtype === "select" && true}
    error={!!handleError(field.name)}
    helperText={handleError(field.name)}
    onChange={handleChange}
  >
    {selectFieldArray.map((option) => (
        <MenuItem
          key={option.value}
          value={option.value}>
          {option.label}
        </MenuItem>
      ))}
  </TextField>
  )
}

export default SelectField