import React from "react";

function ThankYou ({ thankYouScreen }) {


    return (
        <div>
          <img
            src={thankYouScreen}
            alt=""
            style={{ width: "100%", height: "100vh" }}
          />
        </div>
    )
}

export default ThankYou;