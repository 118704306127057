import developmentConfig from './development';
import e2eQaConfigs from './e2eqa';
import productionConfigs from './production';
import qaConfigs from './qa';

const configsMap = {
  qa: qaConfigs,
  dev: developmentConfig,
  e2eqa: e2eQaConfigs,
  production: productionConfigs,
};

const getEnvConfigs = () => {
  const currentEnv = process.env.REACT_APP_DEPLOY_ENV || 'dev';

  if (!currentEnv || !configsMap[currentEnv]) {
    process.stdout("Environment not found")
    process.exit(1)
  }

  return configsMap[currentEnv];
};

export default getEnvConfigs();
