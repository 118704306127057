import Button from "@mui/material/Button"
import Dialog from "@mui/material/Dialog"
import DialogActions from "@mui/material/DialogActions"
import DialogContent from "@mui/material/DialogContent"
import DialogContentText from "@mui/material/DialogContentText"
import DialogTitle from "@mui/material/DialogTitle"
import { get } from "lodash"
import React, { useEffect } from "react"
import { useNavigate } from "react-router-dom"
import useAppConfig from "../../hooks/useAppConfig"
import moment from "moment/moment"
import { doesUserHaveImmediateExpiringPoints } from "../../service/loyalty-points"

const AlertDialog = ({ open, title, content, handleToggle, actions }) => {
  const renderActions = () => {
    const actionsContext = actions || []
    return actionsContext.map(({ label, action }) => (
      <Button onClick={action}>{label}</Button>
    ))
  }

  return (
    <div>
      <Dialog open={open} onClose={handleToggle}>
        <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {content}
          </DialogContentText>
        </DialogContent>
        <DialogActions>{renderActions()}</DialogActions>
      </Dialog>
    </div>
  )
}

const PushNotification = () => {
  const pwaConfig = useAppConfig()
  const [notify, setNotify] = React.useState(false)
  const navigate = useNavigate()

  const handleNotificationToggle = () => setNotify((notified) => !notified)

  const LoyaltyPushNotification = () => {

    if (!doesUserHaveImmediateExpiringPoints(pwaConfig)) return <></>

    const immediateExpiringPoints = get(
      pwaConfig,
      "loyaltyDetails.immediateExpiringPoints",
      {}
    )
    const { expiringpoints = 0, max_expire_date = null } =
      immediateExpiringPoints

    const formattedDate = moment(max_expire_date).fromNow()

    const getAlertContent = () => (
      <div>
        <p>
          <b>{`${expiringpoints} `}</b> points are going to expire{" "}
          {formattedDate}
        </p>
      </div>
    )

    return (
      <AlertDialog
        open={notify}
        title={"Loyalty Points Alert"}
        content={getAlertContent()}
        handleToggle={handleNotificationToggle}
        actions={[
          { action: handleNotificationToggle, label: "Close" },
          {
            action: () => {
              handleNotificationToggle(() => false)
              navigate("/loyalty-info")
            },
            label: "View",
          },
        ]}
      />
    )
  }

  useEffect(() => {
    if (doesUserHaveImmediateExpiringPoints(pwaConfig)) setNotify(true)
    // eslint-disable-next-line
  }, [])

  return (
    <>
      <LoyaltyPushNotification />
    </>
  )
}

export default PushNotification
