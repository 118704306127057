import React from 'react'
import { Container, Typography, Button } from '@mui/material'
import SubscriptionImage from '../assets/subscription-static.webp'

function SubscriptionScreen() {
  return (
    <Container
      maxWidth="sm"
      style={{
        paddingTop: '2rem',
      }}
    >
      <img
        src={SubscriptionImage}
        alt="Subscription"
        style={{
          width: '100%',
          borderRadius: '0.3rem',
          boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px',
        }}
      />
      <Typography variant="h4" align="center" gutterBottom>
        Oops Subscription Not Found!
      </Typography>
      <Typography variant="body1" align="center" paragraph>
        Unlock exclusive content and features with our subscription.
      </Typography>
      <Button variant="contained" color="primary" size="large" fullWidth>
        Contact Admin
      </Button>
    </Container>
  )
}

export default SubscriptionScreen
