import React, {useState, useEffect} from 'react';
import sanitizeHtml from 'sanitize-html';
import HeroBanner from "../../Layout/HeroBanner";
import Footer from "../../Layout/Footer";
import useAppConfig from "../../hooks/useAppConfig";
import AdditionalTextContent from '../LoyaltyInfo/AdditionalText';
import _ from 'lodash';

function getAllowedTags() {
	const defaultTags = [
		"address", "article", "aside", "footer", "header", "h1", "h2", "h3", "h4",
		"h5", "h6", "hgroup", "main", "nav", "section", "blockquote", "dd", "div",
		"dl", "dt", "figcaption", "figure", "hr", "li", "main", "ol", "p", "pre",
		"ul", "a", "abbr", "b", "bdi", "bdo", "br", "cite", "code", "data", "dfn",
		"em", "i", "kbd", "mark", "q", "rb", "rp", "rt", "rtc", "ruby", "s", "samp",
		"small", "span", "strong", "sub", "sup", "time", "u", "var", "wbr", "caption",
		"col", "colgroup", "table", "tbody", "td", "tfoot", "th", "thead", "tr", 
		"input", "button", "img", "textarea", "label", "form",
	];
	return defaultTags.concat(['img', 'style', 'meta', 'link']);
}

const defaultOptions = {
	allowedTags: getAllowedTags(),
	allowedAttributes: {
		'link' : ['href', 'rel', 'type'],
		'img': ['src', 'alt'],
		a: ["href", "target"],
		'*' : ['class', 'id'],
	},
	transformTags: {
        'a': (_tagName, attribs) => {
          return {
            tagName: 'a',
            attribs: Object.assign({}, attribs, { target: '_blank' })
          };
        }
      },
	nonTextTags: [ 'style', 'script', 'textarea', 'option', 'title'],
	// can lead to CORS error, add entry in nginx to allow requests.
	allowedIframeHostnames: [
		'rules.casa.ajira.tech',
		'rules.casaqa.ajira.tech',
		'rules.qa.casaretail.ai',
	],
	allowedIframeDomains: [
		'qa.casarewards.com'
	]
}

const sanitize = (dirty, options={}) => ({
	__html: sanitizeHtml(dirty, { ...defaultOptions, ...options })
});

const SanitizeHTML = ({html, options={}, moduleName}) => {

	const pwaConfig = useAppConfig();
	const [bannerImg, setBannerImg] = useState("");
	const [footerImg, setFooterImg] = useState("");
	const isAdditionalText = _.get(pwaConfig, "appConfig.config.additionalText.ALL_PAGES.html") === "<p><br></p>";
	const { typography } = pwaConfig?.appConfig?.config;

	useEffect(() => {
    if (pwaConfig.appConfig.config.notForAllPage === "false") {
	  setBannerImg(pwaConfig.appConfig.config.modules.FOR_ALL_PAGE.headerImage);
	  setFooterImg(pwaConfig.appConfig.config.modules.FOR_ALL_PAGE.footerImage);
    } else {
      setBannerImg(
        pwaConfig.appConfig.config.modules[moduleName]?.headerImage
          ? pwaConfig.appConfig.config.modules[moduleName].headerImage
          : pwaConfig.appConfig.config.modules.FOR_ALL_PAGE.headerImage
      );
      setFooterImg(
        pwaConfig.appConfig.config.modules[moduleName]?.footerImage
          ? pwaConfig.appConfig.config.modules[moduleName].footerImage
          : pwaConfig.appConfig.config.modules.FOR_ALL_PAGE.footerImage
      );
    }
  }, [pwaConfig, moduleName]);

  return (
  <>
	<HeroBanner src={bannerImg} />
	<div dangerouslySetInnerHTML={sanitize(html, options)} style={{ fontFamily: typography.fontFamily }}/>
	{!isAdditionalText && <AdditionalTextContent />}
	<Footer img={footerImg} />
  </>
  )
};

export default SanitizeHTML;
