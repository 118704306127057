import { Box, CircularProgress } from "@mui/material";
import { LoaderContainer } from "../../../styles/styledComponent";
import Brand from "../Brand";

const Loader = () => {
  return (
    <LoaderContainer>
      <Box component="main">
        <Brand />
        <CircularProgress size={18} />
      </Box>
    </LoaderContainer>
  );
};

export default Loader;
