import axios from 'axios'
import config from '../config'

const { apiServerUrl } = config
export const getInvoiceToken = (jwtPayload) =>
  axios
    .post(`${apiServerUrl}/invoice/token`, jwtPayload)
    .then(({ data }) => data)

export const getInvoiceSurveyResponses = (payload, token) =>
    axios
        .post(`${apiServerUrl}/events/survey/response/user/${token}/all`, payload)
        .then(({ data }) => data)
