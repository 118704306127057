import { useNavigate, useLocation } from "react-router-dom";
import { Headers, StyledTab } from "../../styles/styledComponent";
import { ReactComponent as OfferSymbol } from "../../assets/Offers.svg";
import { Box, Tabs, Typography } from "@mui/material";
import { useState } from "react";
import STRING_CONSTANTS from "../../shared/constants/StringConstants";
import useAppTheme from "../../hooks/useAppTheme";

const HomeHeader = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [value, setValue] = useState(location.pathname);
  const { theme } = useAppTheme();

  let color = theme.palette.primary.main;

  const handleChange = (event, newValue) => {
    setValue(newValue);
    navigate(newValue);
  };
  return (
    <div style={{ marginBottom: 30 }}>
      <Headers>
        <Box style={{ display: "flex", justifyContent: "center" }}>
          <OfferSymbol className="offerIcon" />
          <Typography style={{ fontSize: 25, fontWeight: 600 }}>
            Offers
          </Typography>
        </Box>
      </Headers>
      <Box>
        <Tabs
          value={value}
          onChange={handleChange}
          TabIndicatorProps={{
            style: {
              display: "none",
            },
          }}
        >
          <StyledTab
            backgrounds={color}
            indicatorcolor="none"
            value="/offer/available-offers"
            label={STRING_CONSTANTS.AVAILABLE_OFFERS}
            style={{
              borderTopLeftRadius: "6px",
              borderBottomLeftRadius: "6px",
              fontWeight: 600,
              width: "50%",
            }}
          />
          <StyledTab
            backgrounds={color}
            value="/offer/reedemed-offers"
            label={STRING_CONSTANTS.REEDEMED_OFFERS}
            style={{
              borderTopRightRadius: "6px",
              borderBottomRightRadius: "6px",
              fontWeight: 600,
              width: "50%",
            }}
          />
        </Tabs>
      </Box>
    </div>
  );
};

export default HomeHeader;
