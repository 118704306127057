import { IconButton } from '@mui/material';

import MenuIcon from '../../../../shared/components/icons/Menu';

const MenuButton = ({ className, onClick }) => {
  return (
    <IconButton className={className} onClick={onClick}>
      <MenuIcon />
    </IconButton>
  );
};

export default MenuButton;
