import { useEffect, useState } from "react";
import useAppConfig from "../../hooks/useAppConfig";
import Footer from "../../Layout/Footer";
import HeroBanner from "../../Layout/HeroBanner";
import { LoyaltyInfoContainer } from "../../styles/styledComponent";
import LoyaltyPoints from "./LoyaltyPoints";
import AdditionalTextContent from "./AdditionalText";
import _ from "lodash";

const LoyaltyInfo = () => {
  const [bannerImg, setBannerImg] = useState();
  const [footerImg, setFooterImg] = useState();
  const pwaConfig = useAppConfig();
  const isAdditionalText = _.get(pwaConfig, "appConfig.config.additionalText.ALL_PAGES.html") === "<p><br></p>";

  useEffect(() => {
    if (pwaConfig.appConfig.config.notForAllPage === "false") {
      setBannerImg(pwaConfig.appConfig.config.modules.FOR_ALL_PAGE.headerImage);
      setFooterImg(pwaConfig.appConfig.config.modules.FOR_ALL_PAGE.footerImage);
    } else {
      setBannerImg(
        pwaConfig?.appConfig?.config?.modules?.LOYALTY_DETAILS?.headerImage
          ? pwaConfig?.appConfig?.config?.modules?.LOYALTY_DETAILS?.headerImage
          : pwaConfig?.appConfig?.config?.modules?.FOR_ALL_PAGE?.headerImage
      );
      setFooterImg(
        pwaConfig?.appConfig?.config?.modules?.LOYALTY_DETAILS?.footerImage
          ? pwaConfig?.appConfig?.config?.modules?.LOYALTY_DETAILS?.footerImage
          : pwaConfig?.appConfig?.config?.modules?.FOR_ALL_PAGE?.footerImage
      );
    }
  }, [pwaConfig]);

  return (
    <>
      <LoyaltyInfoContainer>
        <HeroBanner src={bannerImg} />
        <LoyaltyPoints />
        {!isAdditionalText && <AdditionalTextContent />}
        <Footer img={footerImg} />
      </LoyaltyInfoContainer>
    </>
  );
};

export default LoyaltyInfo;
