import { Box, Typography } from "@mui/material";
// import { startCase } from "lodash";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { getRedeemed } from "../../../service/offer.service";
import STRING_CONSTANTS from "../../../shared/constants/StringConstants";
import IfEmpty from "../../../shared/constants/IfEmpty";
import { ReactComponent as NoRedeem } from "../../../assets/No_offers_ui.svg";
import { ReactComponent as Star } from "../../../assets/emojione_star.svg";
import loader from "../../../assets/Fountain.gif";
import {
  OfferCard,
  OfferCode,
  OnlyforYouBox,
  Reedemed,
  Textval,
} from "../../../styles/styledComponent";
import useAppConfig from "../../../hooks/useAppConfig";
import _ from "lodash";

const Redeemed = () => {
  const [redemeed, setRedeemed] = useState([]);
  const [loading, setLoading] = useState(false);

  const Config = useAppConfig();
  const Business = Config?.appConfig?.selecteBussines?.name;
  useEffect(() => {
    setLoading(true);
    getRedeemed(Business).then((response) => {
      const uniqRedeemedOffers = _.uniqBy(response, el => _.get(el, 'offerCode'));
      setRedeemed(uniqRedeemedOffers);
      setLoading(false);
    });
  }, [Business]);
  return (
    <div style={{ marginBottom: 30 }}>
      {loading ? (
        <div className="loadingGif">
          <img src={loader} alt="Loading..." />
        </div>
      ) : redemeed?.length > 0 ? (
        redemeed.map((obj, index) => {
          return (
            <OfferCard key={index}>
              {obj.currentOffer.isCustomerSpecific === true && (
                <Box className="display">
                  {/* <Typography variant="caption">
                  {" "}
                  {obj?.offerBasedOn
                    ? startCase(
                        `${obj.offerBasedOn} ${STRING_CONSTANTS.OFFER_TYPE}`
                      )
                    : "Not Applicable"}
                </Typography> */}
                  <OnlyforYouBox>
                    <Box
                      style={{
                        display: "flex",
                        justifyContent: "flex-start",
                        marginLeft: 15,
                        marginTop: 5,
                      }}
                    >
                      <Star className="staricon" />
                      <Textval>{STRING_CONSTANTS.ONLY_FOR_YOU}</Textval>
                    </Box>
                  </OnlyforYouBox>
                </Box>
              )}
              {/* <Box className="display"> */}
              <Typography
                style={{ fontSize: 14, fontWeight: 600, marginBottom: 5 }}
              >
                Offer Name: {obj?.categoryInfo?.category || "--"}
              </Typography>
              <Typography style={{ fontSize: 14, marginBottom: 10 }}>
                <b> Redeemed On: </b>{" "}
                {moment(`${obj?.createdAt}`).format("DD-MMM-YYYY HH:mm")}
              </Typography>
              {/* </Box> */}

              <Typography style={{ fontSize: 14, marginBottom: 10 }}>
                <span style={{ fontWeight: 600 }}> Offer Description: </span>

                {obj?.currentOffer?.description}
              </Typography>
              {/* <Typography style={{ marginBottom: 10, fontSize: 12 }}>
              Bill ID: <b>Bill 12345tghn</b>
            </Typography> */}
              <Box className="display">
                <OfferCode>
                  {" "}
                  {STRING_CONSTANTS.OFFER_CODE} {obj?.offerCode}
                </OfferCode>
                <Box className="boxreedemed">
                  <Reedemed>Redeemed</Reedemed>
                </Box>
              </Box>
            </OfferCard>
          );
        })
      ) : (
        <IfEmpty
          Icon={NoRedeem}
          head={STRING_CONSTANTS.EMPTY}
          desc={STRING_CONSTANTS.NO_OFFERS_FOUND}
        />
      )}
    </div>
  );
};

export default Redeemed;
