import { Button, Dialog, DialogActions, DialogContent } from "@mui/material";
import QRCode from "react-qr-code";

const QRCodeDialog = (props) => {
  const { onClose, mobile } = props;

  return (
    <Dialog
      fullWidth
      open
      onClose={onClose}
      sx={{
        "& .MuiDialog-container": {
          "& .MuiPaper-root": {
            maxWidth: 400,
          },
        },
        backdropFilter: "blur(5px)",
      }}
    >
      <DialogContent className="qrCode">
        <QRCode value={mobile} />
      </DialogContent>
      <DialogActions sx={{ display: "flex", justifyContent: "center" }}>
        <Button variant="outlined" onClick={onClose} className="qrButton">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default QRCodeDialog;
