import _ from 'lodash';
import * as regexConstants from './constants/RegExp';

const validations = {
    mobile: (mobile) => regexConstants.MOBILE_REGEXP.test(mobile),
    name: (name) => regexConstants.NAME_REGEXP.test(name),
    email: (email) => regexConstants.EMAIL_REGEXP.test(email),
    birthday: (birthday) => !_.isEmpty(birthday),
    placeOfRegistration: (placeOfRegistration) => !_.isEmpty(placeOfRegistration),
    city: (city) => !_.isEmpty(city),
    gender: (gender) => !_.isEmpty(gender),
    shoeSize: (shoeSize) => !_.isEmpty(shoeSize)
};

const editValidations = {
    mobile: (mobile) => /^(^[6-9][0-9]{0,9}$|)$/.test(mobile)
};

const onEdit = {
    name: (name) => name.replace(/[^a-zA-Z\s.]/g, "")
}

export function validate(key, value) {
    return validations[key](value);
}

export function canAllowEdit(key, value) {
    if (_.has(editValidations, key)) {
        return editValidations[key](value.toString());
    }
    return true;
}

export function onEditAllowed(key, value) {
    if (_.has(onEdit, key)) {
        return onEdit[key](value.toString());
    }
    return value;
}
