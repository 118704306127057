import React from "react";
import { Dialog, Box, Button, Typography } from "@mui/material";
import { ReactComponent as PaperPlanePositive } from "../../assets/Paper_Plane_positive.svg";
import { ReactComponent as PaperPlaneNegative } from "../../assets/Paper_Plane_negative.svg";
import PersonAddAltOutlinedIcon from "@mui/icons-material/PersonAddAltOutlined";
import { useTheme } from "@emotion/react";
import { useNavigate } from "react-router-dom";
import InvalidContacts from "./InvalidContacts";
import style from './styles/InviteDialog.module.scss'; 

const InviteDialog = ({
  open,
  onClose,
  onAnotherInvite,
  referrals = {
    validReferrals: [],
    alreadyReferredUsers: [],
    existingUsers: [],
    invalidUsers: [],
  },
}) => {
  const navigate = useNavigate();
  const theme = useTheme();

  const validReferralsCount = referrals.validReferrals?.length;
  const notEligibleReferralsCount =
    referrals.alreadyReferredUsers?.length +
    referrals.existingUsers?.length +
    referrals.invalidUsers?.length;

  const handleGoHome = () => {
    navigate("/");
  };

  const handleAnotherInvite = () => {
    onAnotherInvite();
    onClose();
  };
  return (
    <Dialog open={open} onClose={onClose} fullWidth>
      <Box className={style.dialogContainer}>
        {validReferralsCount > 0 ? (
          <PaperPlanePositive
            width="80%"
            height="120"
            color={theme.palette.primary.main}
          />
        ) : (
          <PaperPlaneNegative width="80%" height="120" />
        )}
        {validReferralsCount > 0 && (
          <Typography variant="h6" fontWeight="bold">
            {validReferralsCount >= 1 && notEligibleReferralsCount !== 0
              ? `${validReferralsCount} Invites Sent Successfully`
              : "Invite Sent Successfully"}
          </Typography>
        )}
        {validReferralsCount === 0 && notEligibleReferralsCount === 1 && (
          <Typography variant="h6" fontWeight="bold">
            This number is not eligible for referral
            {referrals.alreadyReferredUsers?.length === 1 && (
              <Typography variant="h6">(Already Referred)</Typography>
            )}
            {referrals.existingUsers?.length === 1 && (
              <Typography variant="h6">(Existing User)</Typography>
            )}
            {referrals.invalidUsers?.length === 1 && (
              <Typography variant="h6">(Invalid Referrer)</Typography>
            )}
            <Typography></Typography>
          </Typography>
        )}
        {validReferralsCount > 0 && notEligibleReferralsCount > 0 && (
          <InvalidContacts
            alreadyReferredUsers={referrals.alreadyReferredUsers}
            existingUsers={referrals.existingUsers}
            invalidUsers={referrals.invalidUsers}
          />
        )}
        {validReferralsCount === 0 && notEligibleReferralsCount > 1 && (
          <InvalidContacts
            alreadyReferredUsers={referrals.alreadyReferredUsers}
            existingUsers={referrals.existingUsers}
            invalidUsers={referrals.invalidUsers}
          />
        )}

        <Box className={style.buttonContainer}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleAnotherInvite}
            fullWidth
          >
            <PersonAddAltOutlinedIcon className={style.icon} />
            <Typography variant="body1" fontWeight="bold">
              Invite More
            </Typography>
          </Button>
          <Button
            variant="contained"
            color="secondary"
            onClick={handleGoHome}
            fullWidth
          >
            <Typography variant="body1" fontWeight="bold">
              Go Home
            </Typography>
          </Button>
        </Box>
      </Box>
    </Dialog>
  );
};

export default InviteDialog;
