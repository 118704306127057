import { createTheme, ThemeProvider } from "@mui/material";
import { createContext, useState } from "react";

import defaultTheme from "../theme";

const AppThemeContext = createContext(defaultTheme);

export const AppThemeProvider = ({ children }) => {
  const [theme, setTheme] = useState(defaultTheme);

  const handleSetTheme = (selectedTheme) => {
    setTheme(createTheme({ ...theme, ...selectedTheme }));
  };

  return (
    <AppThemeContext.Provider value={{ theme, setTheme: handleSetTheme }}>
      <ThemeProvider theme={theme}>{children}</ThemeProvider>
    </AppThemeContext.Provider>
  );
};

export default AppThemeContext;
