import axios from "axios";
import config from "../config";
import { getPwaToken } from "../context/Auth";
const { ruleServerUrl, apiServerUrl } = config;

export const sendLink = (referFriend) => {
  return axios
    .post(`${ruleServerUrl}/referral/save`, referFriend)
    .then((response) => response.data)
    .catch((err) => {
      return err;
    });
};

export const sendLinkV2 = (referralDetails) => {
  console.log("ReferralDetails", referralDetails);
  const config = {
    headers: {
      Authorization: `Bearer ${getPwaToken()}`,
    }
  }

  return axios
    .post(`${apiServerUrl}/friend-referral`, referralDetails, config)
    .then((response) => response.data)
    .catch((err) => {
      return err;
    });
}