/* eslint-disable react-hooks/exhaustive-deps */
import React, {useState, useEffect} from 'react'
import useAppConfig from "../../hooks/useAppConfig";
import {useParams} from 'react-router-dom';
import SanitizeHTML from '../DynamicForm/SanitizeHTML';
import * as _ from 'lodash';

function CustomHTML() {
    const pwaConfig = useAppConfig();
    var {moduleName} = useParams();
    const [html, setHtml] = useState('');

    const url = _.get(pwaConfig, `appConfig.config.menu.${moduleName}.path`);

    // can lead to CORS error, add entry in nginx to allow requests.
    const fetchHTML = async (url) => await fetch(url).then(r => r.text())

    useEffect(() =>{
        fetchHTML(url)
            .then(setHtml)
    }, [moduleName]);

  return <SanitizeHTML html={html} moduleName={moduleName}/>
}

export default CustomHTML