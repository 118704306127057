const ROUTE_PATHS = {
  HOME: "/",
  OFFERS: "/offer",
  TRANSACTION_HISTORY: "/transaction-history",
  AVAILABLE_OFFERS: "available-offers",
  REEDEMED_OFFERS: "reedemed-offers",
  LOYALTY_INFO: "/loyalty-info",
  REFER_FRIEND: "/refer-friend",
  REGISTER: `register/:businessHashId`,
  ERROR: "/fail",
  HTML : `/html/:moduleName`,
  QR_REDIRECT: `/customer-verify/:businessHashId`,
  SIGN_UP: `sign-up/:businessHashId`,
  SIGN_UP_SHORTENED: `verify`
};

export default ROUTE_PATHS;
