import _ from "lodash";
import React, { useEffect, useState } from "react";
import useAppConfig from "../../hooks/useAppConfig";
import { getLoyaltyPoints } from "../../service/loyaltyinfo.service";
import PaginationTable from "./PaginationTable";
import IfEmpty from "../../shared/constants/IfEmpty";
import { ReactComponent as NoLoyalty } from "../../assets/No_Redeem_Ui.svg";
import loader from "../../assets/Fountain.gif";
import STRING_CONSTANTS from "../../shared/constants/StringConstants";
export default function   TableWithAPI() {
  const Config = useAppConfig();
  const [cells, setCells] = useState([]);
  const [loading, setLoading] = useState(false);
  const Business = Config?.appConfig?.selecteBussines?.name;
  useEffect(() => {
    setLoading(true);
    getLoyaltyPoints(Business).then((response) => {
      setCells(response?.data.viewDetails);
      setLoading(false);
    }).catch(() => {
      setLoading(false);
    });
  }, [Business]);

  let openPoint = [];
  _.get(cells, "closingPoints", []).forEach((val) => {
    let closing = new Date(val.month_end);
    let mm = String(closing.getMonth() + 1).padStart(2, "0"); //January is 0!
    let yyyy = closing.getFullYear();
    let closingPoint = yyyy + "-" + mm;

    let monthnew = new Date(closingPoint);
    monthnew.setMonth(monthnew.getMonth() + 1);
    openPoint.push({
      month_end: `${monthnew.getFullYear()}-${
        monthnew.getMonth() < 9
          ? // eslint-disable-next-line no-useless-concat
            `0` + `${monthnew.getMonth() + 1}`
          : monthnew.getMonth() + 1
      }-01`,
      sum: val.sum,
    });
  });

  let currentMonth = new Date().toLocaleString("default", { month: "short" });

  const result = _.map(_.get(cells, "closingPoints"), (entry) => {
    let closing = new Date(entry.month_end);
    let mm = String(closing.getMonth() + 1).padStart(2, "0"); //January is 0!
    let yyyy = closing.getFullYear();
    let closingMonth = yyyy + "-" + mm;
    const expiry = _.filter(_.get(cells, "expiringPoints"), (expiry) =>
      _.get(expiry, "month_end", "").startsWith(closingMonth)
    );
    const loyalty = _.filter(_.get(cells, "billDetails"), (loyalty) =>
      _.get(loyalty, "date", "").startsWith(closingMonth)
    );
    const openingPoints = _.filter(openPoint, (openingPoints) =>
      _.get(openingPoints, "month_end", "").startsWith(closingMonth)
    );
    return {
      ...entry,
      billDetails: loyalty,
      expiryByMonth: expiry,
      openingPoints: openingPoints,
    };
  });
  const columns = React.useMemo(
    () => [
      {
        Header: "Month",
        accessor: "month_end",
      },
    ],
    []
  );
  const data = React.useMemo(() => result, [result]);

  return (
    <>
      {loading ? (
        <div className="loadingGif">
          <img src={loader} alt="Loading..." />
        </div>
      ) : cells?.billDetails?.length > 0 ? (
        <PaginationTable
          columns={columns}
          data={data}
          currentMonth={currentMonth}
        />
      ) : (
        <IfEmpty
          Icon={NoLoyalty}
          head={STRING_CONSTANTS.EMPTY}
          desc="No Loyalty points for you yet,
        Purchase to start your Loyalty journey"
        />
      )}
    </>
  );
}
