import ReferralInvitation from "./ReferralInvitation";
import ContactList from "./ContactList";
import { useEffect, useState } from "react";
import useAppConfig from "../../hooks/useAppConfig";
import Footer from "../../Layout/Footer";
import HeroBanner from "../../Layout/HeroBanner";
import { ReferFriendContainer } from "../../styles/styledComponent";
import ReferralForm from "./ReferralForm";
import _ from "lodash";
import AdditionalTextContent from "../LoyaltyInfo/AdditionalText";

const ReferFriendV2 = () => {
  const [contactPickerEnabled, setContactPickerEnabled] = useState(true);
  const [contacts, setContacts] = useState([]);
  const [clearContacts, setClearContacts] = useState(false);
  const pwaConfig = useAppConfig();

  const [bannerImg, setBannerImg] = useState();
  const [footerImg, setFooterImg] = useState();

  const isAdditionalText = _.get(pwaConfig, "appConfig.config.additionalText.ALL_PAGES.html") === "<p><br></p>";

  useEffect(() => {
    if (pwaConfig.appConfig.config.notForAllPage === "false") {
      setBannerImg(pwaConfig.appConfig.config.modules.FOR_ALL_PAGE.headerImage);
      setFooterImg(pwaConfig.appConfig.config.modules.FOR_ALL_PAGE.footerImage);
    } else {
      setBannerImg(
        pwaConfig?.appConfig?.config?.modules?.REFER_A_FRIEND?.headerImage
          ? pwaConfig?.appConfig?.config?.modules?.REFER_A_FRIEND?.headerImage
          : pwaConfig?.appConfig?.config?.modules?.FOR_ALL_PAGE?.headerImage
      );
      setFooterImg(
        pwaConfig?.appConfig?.config?.modules?.REFER_A_FRIEND?.footerImage
          ? pwaConfig?.appConfig?.config?.modules?.REFER_A_FRIEND?.footerImage
          : pwaConfig?.appConfig?.config?.modules?.FOR_ALL_PAGE?.footerImage
      );
    }
  }, [pwaConfig]);

  useEffect(() => {
    if (clearContacts) {
      setContacts([]);
      setClearContacts(false);
    }
  }, [clearContacts]);

  const handleSelectContacts = async () => {
    if ("contacts" in navigator && "ContactsManager" in window) {
      try {
        const props = await navigator.contacts.getProperties();
        const selectedContacts = await navigator.contacts.select(props, {
          multiple: true,
        });

        const newContacts = selectedContacts.filter((newContact) => {
          return !contacts.some(
            (existingContact) =>
              existingContact.tel &&
              newContact.tel &&
              existingContact.tel[0] === newContact.tel[0]
          );
        });

        setContacts((prevContacts) => [...prevContacts, ...newContacts]);
      } catch (error) {
        if (error.name === "TypeError") {
          setContactPickerEnabled(false);
        } else {
          console.error("Error fetching contacts:", error);
        }
      }
    } else {
      setContactPickerEnabled(false);
      console.error("Contacts API not supported");
    }
  };

  const handleRemoveContact = (index) => {
    setContacts((prevContacts) => prevContacts.filter((_, i) => i !== index));
  };

  return (
    <ReferFriendContainer>
      <HeroBanner src={bannerImg} />
      {contactPickerEnabled ?
       (
        contacts.length > 0 ? (
          <ContactList
            contacts={contacts}
            handleSelectContacts={handleSelectContacts}
            handleRemoveContact={handleRemoveContact}
            setClearContacts={setClearContacts}
          />
        ) : (
          <ReferralInvitation handleSelectContacts={handleSelectContacts} />
        )
      ) : (
        <ReferralForm />
      )
      }
      {!isAdditionalText && <AdditionalTextContent />}
      <Footer img={footerImg} />
    </ReferFriendContainer>
  );
};

export default ReferFriendV2;
