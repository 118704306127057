import { useMediaQuery, useTheme } from "@mui/material";
import { useLayoutEffect } from "react";
import { NavLink } from "react-router-dom";

import ListOfLinks from "../../../../shared/components/ListOfLinks";
import { NavLinks } from "../../../../styles/styledComponent";
import SideBar from "../SideBar";

const NavMenu = ({ links, open, onClose }) => {
  const { breakpoints } = useTheme();
  const isMobileView = useMediaQuery(breakpoints.down("md"));
  useLayoutEffect(() => {
    !isMobileView && onClose();
  }, [isMobileView, onClose]);

  const content = (
    <NavLinks>
      <ListOfLinks
        onLinkClick={onClose}
        links={links}
        component={NavLink}
        isMobileView={isMobileView}
        end
      />
    </NavLinks>
  );

  if (isMobileView) {
    return (
      <SideBar open={open} onClose={onClose}>
        {content}
      </SideBar>
    );
  }

  return content;
};

export default NavMenu;
