import { createSvgIcon } from "@mui/material";

const Menu = createSvgIcon(
  <svg viewBox="0 0 24 24" fill="curretColor">
    <path d="M3 18h18v-2H3v2zm0-5h18v-2H3v2zm0-7v2h18V6H3z"></path>
  </svg>,
  "Menu"
);

export default Menu;
