  import _ from "lodash";
import useAppConfig from "../../hooks/useAppConfig";
import { TransactionCard } from "../../styles/styledComponent";
import sanitizeHtml from 'sanitize-html';

  const AdditionalTextContent = () => {
    const pwaConfig = useAppConfig();
    const { typography } = pwaConfig?.appConfig?.config;

    const getAllowedTags = () => {
        const defaultTags = [
        "address", "article", "aside", "footer", "header", "h1", "h2", "h3", "h4",
        "h5", "h6", "hgroup", "main", "nav", "section", "blockquote", "dd", "div",
        "dl", "dt", "figcaption", "figure", "hr", "li", "main", "ol", "p", "pre",
        "ul", "a", "abbr", "b", "bdi", "bdo", "br", "cite", "code", "data", "dfn",
        "em", "i", "kbd", "mark", "q", "rb", "rp", "rt", "rtc", "ruby", "s", "samp",
        "small", "span", "strong", "sub", "sup", "time", "u", "var", "wbr", "caption",
        "col", "colgroup", "table", "tbody", "td", "tfoot", "th", "thead", "tr"
      ];
      return defaultTags.concat(['img', 'style', 'meta', 'link']);
    }
    
    const defaultOptions = {
      allowedTags: getAllowedTags(),
      allowedAttributes: {
        link: ["href", "rel", "type"],
        a: ["href", "target"],
        "*": ["class", "id"],
      },
      transformTags: {
        'a': (_tagName, attribs) => {
          return {
            tagName: 'a',
            attribs: Object.assign({}, attribs, { target: '_blank' })
          };
        }
      },
      nonTextTags: ["style", "script", "textarea", "option", "title"],
      // can lead to CORS error, add entry in nginx to allow requests.
      allowedIframeHostnames: [
        "rules.casa.ajira.tech",
        "rules.casaqa.ajira.tech",
        "rules.qa.casaretail.ai",
        "*.qa.casarewards.com",
      ],
      allowedIframeDomains: ["casarewards.com"],
    };
    
    const sanitize = (dirty, options = {}) => ({
      __html: sanitizeHtml(dirty, { ...defaultOptions, ...options }),
    });
    
    const getSanitizedHtml = () => sanitize(_.get(pwaConfig, "appConfig.config.additionalText.ALL_PAGES.html"), {})
    
    const renderAdditionalHtmlContent = () => {
      const sanitizedHtml = getSanitizedHtml();
      if (_.isEmpty(_.get(sanitizedHtml, "__html"))) return <></>;

      return (
        <TransactionCard dangerouslySetInnerHTML={sanitizedHtml} style={{ marginInline: "0", paddingLeft: "1rem", fontFamily: typography.fontFamily }} className="additionalText" />
      );
    };
    
    return renderAdditionalHtmlContent();
};
  
export default AdditionalTextContent;
  