import React from 'react'

function CustomLogo({text}) {
  return (
    <svg height="24" width="26">
      <circle cx="13" cy="12" r="45%" fill="#475569" />
      <text x="7.5" y="18" fill="white">{text}</text>
</svg>
  )
}

export default CustomLogo