import { get, isEmpty, toSafeInteger } from "lodash"

export const doesUserHaveImmediateExpiringPoints = (pwaConfig) => {
  const immediateExpiringPoints = get(
    pwaConfig,
    "loyaltyDetails.immediateExpiringPoints"
  )
  const doesHavePointsExpiring =
    !isEmpty(immediateExpiringPoints) &&
    toSafeInteger(get(immediateExpiringPoints, "expiringpoints", 0)) > 0 &&
    get(immediateExpiringPoints, "max_expire_date")
  return doesHavePointsExpiring
}
