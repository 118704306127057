import { Box, Drawer as MuiDrawer, styled, Typography } from "@mui/material";
import useAppTheme from "../../../../hooks/useAppTheme";
import { SIDE_MENU_WIDTH as SIDE_BAR_WIDTH } from "../../../../shared/constants/style";
import { ReactComponent as ProfilePic } from "../../../../assets/profile_icon.svg";
import { useEffect, useState } from "react";
import { getProfile } from "../../../../service/profile";

const SideBar = ({ children, open, onClose }) => {
  const [name, setName] = useState("");
  const [mobile, setMobile] = useState("");
  const { theme } = useAppTheme();

  useEffect(() => {
    getProfile().then((res) => {
      setName(res.name);
      setMobile(res.mobile);
    });
  }, []);
  return (
    <Drawer
      variant="temporary"
      open={open}
      onClose={onClose}
      sidenavcolor={theme.palette.secondary.main}
    >
      <Box style={{ backgroundColor: "white", padding: 15, display: "flex" }}>
        <ProfilePic style={{ marginRight: 10, width: 40, height: 40 }} />
        <Box style={{ display: "flex", flexDirection: "column" }}>
          <Typography style={{ fontWeight: 600 }}>{name}</Typography>
          <Typography style={{ fontSize: 12 }}>{mobile}</Typography>
        </Box>
      </Box>
      {children}
    </Drawer>
  );
};

const Drawer = styled(MuiDrawer)((props) => {
  return {
    ".MuiPaper-root": {
      minWidth: SIDE_BAR_WIDTH,
      backgroundColor: props.sidenavcolor || "#1a1c26",
    },
    ".closeIconWrap": {
      textAlign: "end",
    },
    nav: {
      ul: {
        display: "flex",
        flexDirection: "column",
        gap: "1.5rem",
        alignItems: "flex-start",
      },
    },
  };
});

export default SideBar;
