import {
  Typography,
  IconButton,
  Button,
  CircularProgress,
  Container,
  Box,
} from "@mui/material";
import PersonAddAltOutlinedIcon from "@mui/icons-material/PersonAddAltOutlined";
import Contact from "./Contact";
import { sendLinkV2 } from "../../service/referFriend.service";
import { useState } from "react";
import { ReferContainer } from "../../styles/styledComponent";
import { ReactComponent as Send } from "../../assets/send.svg";
import InviteDialog from "./InviteDialog";
import style from "./styles/ContactList.module.scss";
import ErrorToast from "./ErrorToast";

const ContactList = ({
  contacts,
  handleSelectContacts,
  handleRemoveContact,
  setClearContacts,
}) => {
  const [loading, setLoading] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [validationError, setValidationError] = useState(false);
  const [apiError, setApiError] = useState(false);
  const [referrals, setReferrals] = useState({
    validReferrals: [],
    alreadyReferredUsers: [],
    existingUsers: [],
    invalidUsers: [],
  });

  const handleCloseDialog = () => {
    setDialogOpen(false);
  };

  const handleOpenDialog = () => {
    setDialogOpen(true);
  };
  
  const handleAnotherInvite = () => {
    setClearContacts(true);
    handleSelectContacts();
  };
  
  const isValidMobile = (mobile) => {
    const mobilePattern = /^(\+91[\s]?)?[6-9]\d{4}[\s]?\d{5}$/;
    return mobilePattern.test(mobile);
  };
  
  const validateContacts = () => {
    return contacts.every((contact) => isValidMobile(contact.tel[0]));
  };
  
  const getFormattedContacts = () => {
    return contacts.map((contact) => ({
      name: contact.name ? contact.name[0] : "NA",
      mobile: contact.tel[0],
    }));
  };
  
  const handleCloseErrorToast = () => {
    setValidationError(false);
  };
  
  const handleInviteButton =  async () => { 
    const formattedContacts = getFormattedContacts();
    
    if (!validateContacts()) {
      setValidationError(true);
      return;
    }
    setLoading(true);
    try {
      const responseData = await sendLinkV2({ referrals: formattedContacts });
      console.log(responseData);
      setReferrals(responseData);
    } catch (error) {
      setApiError(true);
    } finally {
      setLoading(false);
    }
    handleOpenDialog();
    setLoading(false);
  };

  return (
    <>
      <ReferContainer>
        <Box className={style.containerHeader}>
          <Typography variant="h6" fontWeight="Bold">
            Selected contacts
          </Typography>
          <IconButton onClick={handleSelectContacts} aria-label="add contact">
            <PersonAddAltOutlinedIcon fontSize="24px" color="primary" />
          </IconButton>
        </Box>
        <Container className={style.containerBody}>
          {contacts.length > 0 &&
            contacts.map((contact, index) => (
              <Contact
                key={contact.tel[0]}
                contact={contact}
                handleRemoveContact={handleRemoveContact.bind(null, index)}
                isValidMobile={isValidMobile(contact.tel[0])}
              />
            ))}
        </Container>
        <Button onClick={handleInviteButton} variant="contained" fullWidth>
          {loading ? (
            <CircularProgress size={24} color="inherit" />
          ) : (
            <Box display="flex" alignItems="center">
              <Send style={{ marginRight: 12 }} />
              <Typography variant="subtitle1" fontWeight={600}>
                Send Invite
              </Typography>
            </Box>
          )}
        </Button>
      </ReferContainer>
      <InviteDialog
        open={dialogOpen}
        onClose={handleCloseDialog}
        onAnotherInvite={handleAnotherInvite}
        referrals={referrals}
      />
      {validationError && (
        <ErrorToast
          severity="error"
          message="Remove invalid / missing numbers."
          handleCloseErrorToast={handleCloseErrorToast}
        />
      )}
      {apiError && (
        <ErrorToast
          severity="error"
          message="Failed to Send Invite. Please try again later."
          handleCloseErrorToast={handleCloseErrorToast}
        />
      )}
    </>
  );
};
export default ContactList;
