import React, { useState } from "react";
import { Accordion, Card, Typography, Container, Box } from "@mui/material";
import Contact from "./Contact";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { ReactComponent as NotEligible } from "../../assets/NotEligible.svg";
import style from "./styles/InvalidContacts.module.scss";

const errorColor = "#d01a2a";

const InvalidContacts = ({
  alreadyReferredUsers,
  existingUsers,
  invalidUsers,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const notEligibleReferralsCount =
    alreadyReferredUsers?.length + existingUsers?.length + invalidUsers?.length;

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  return (
    <Card
      textAlign="center"
      sx={{ border: `2px solid ${errorColor}` }}
    >
      <Box
        onClick={toggleDropdown}
        className={style.header}
      >
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <NotEligible />
          <Typography className={style.title}>
            {notEligibleReferralsCount} Contacts are not eligible
          </Typography>
        </Box>
        {isOpen ? (
          <KeyboardArrowUpIcon color={errorColor} />
        ) : (
          <KeyboardArrowDownIcon color={errorColor} />
        )}
      </Box>

      {isOpen && (
        <Accordion
          expanded={isOpen}
          onChange={toggleDropdown}
          className={style.accordion}
        >
          <Container className={style.container}>
            {alreadyReferredUsers.length > 0 && (
              <>
                <Typography variant="subtitle1">Already Referred</Typography>
                {alreadyReferredUsers.map((referral) => (
                  <Contact key={referral.mobile} contact={referral} />
                ))}
              </>
            )}
            {existingUsers.length > 0 && (
              <>
                <Typography variant="subtitle1">Existing User</Typography>
                {existingUsers.map((referral) => (
                  <Contact key={referral.mobile} contact={referral} />
                ))}
              </>
            )}
            {invalidUsers.length > 0 && (
              <>
                <Typography variant="subtitle1">Invalid Referrer</Typography>
                {invalidUsers.map((referral) => (
                  <Contact key={referral.mobile} contact={referral} />
                ))}
              </>
            )}
          </Container>
        </Accordion>
      )}
    </Card>
  );
};

export default InvalidContacts;
