import { reduce, split, trim } from "lodash"
import STRING_CONSTANTS from "../constants/StringConstants"

const getTokenFromCookie = () => {
  const { COOKIE_NAME } = STRING_CONSTANTS
  const token = reduce(
    split(document.cookie, ";"),
    (acc, val) => {
      const [key, value] = val.split("=")
      if (trim(key) === COOKIE_NAME) acc = value
      return acc
    },
    null
  )
  console.log("token-from-cookie", token)
  return token
}

const setAndGetTokenFromCookie = () => {
  const params = new URLSearchParams(window.location.search)
  const { TOKEN_PARAM_KEY, COOKIE_NAME } = STRING_CONSTANTS

  if (params.has(TOKEN_PARAM_KEY)) {
    const tokenFromUrl = params.get(TOKEN_PARAM_KEY)
    console.log("tokenFromUrl", tokenFromUrl)
    const maxAge = 30 * 24 * 60 * 60
    document.cookie = `${COOKIE_NAME}=${tokenFromUrl};path=/;max-age=${maxAge}`
    return tokenFromUrl
  }

  return getTokenFromCookie()
}

export { setAndGetTokenFromCookie }
