import { ReactComponent as Refer } from "../../assets/refer.svg";
import { Typography, Button, CircularProgress } from "@mui/material";
import STRING_CONSTANTS from "../../shared/constants/StringConstants";
import { ReferContainer } from "../../styles/styledComponent";
import { useState } from "react";
import { useTheme } from "@emotion/react";

const ReferralInvitation = ({ handleSelectContacts }) => {
  const [isLoading, SetIsLoading] = useState(false);
  const theme = useTheme();
  const handleInviteButton = () => {
    SetIsLoading(true);
    handleSelectContacts();
    SetIsLoading(false);
  };
  return (
    <ReferContainer>
      <Refer
        width="100%"
        height="100%"
        style={{
          color: theme.palette.primary.main,
          fill: theme.palette.primary.light,
        }}
      />
      <Typography variant="h5" fontWeight="bold">
        Refer a Friend & Earn
      </Typography>
      <Typography variant="body1">
        Earn points when your friends make their first purchase
      </Typography>
      <Button
        variant="contained"
        color="primary"
        fullWidth
        onClick={handleInviteButton}
      >
        {isLoading ? (
          <CircularProgress size={24} />
        ) : (
          <Typography variant="subtitle1" fontWeight={600}>
            {STRING_CONSTANTS.INVITE_FRIENDS}
          </Typography>
        )}
      </Button>
    </ReferContainer>
  );
};

export default ReferralInvitation;
