import React, { useEffect, useState } from "react";
import {
  ListItem,
  ListItemAvatar,
  ListItemText,
  Avatar,
  IconButton,
  Typography,
} from "@mui/material";
import {
  deepOrange,
  deepPurple,
  teal,
  blue,
  green,
} from "@mui/material/colors";
import CancelIcon from "@mui/icons-material/Cancel";
import styles from "./styles/Contact.module.scss";
import classnames from "../../utils/classnames";
const Contact = ({ contact, handleRemoveContact, isValidMobile = true }) => {
  const [blobUrl, setBlobUrl] = useState(null || undefined);

  useEffect(() => {
    if (contact.icon && contact.icon[0] instanceof Blob) {
      const imgBlob = contact.icon[0];
      const url = URL.createObjectURL(imgBlob);
      setBlobUrl(url);

      return () => {
        URL.revokeObjectURL(url);
      };
    } else {
      setBlobUrl(null);
    }
  }, [contact.icon]);

  const getInitials = (name) => {
    return name.charAt(0).toUpperCase();
  };

  const getColor = () => {
    const colorPairs = [
      { bgcolor: deepOrange[50], color: deepOrange[900] },
      { bgcolor: deepPurple[50], color: deepPurple[900] },
      { bgcolor: teal[50], color: teal[900] },
      { bgcolor: blue[50], color: blue[900] },
      { bgcolor: green[50], color: green[900] },
    ];

    const randomIndex = Math.floor(Math.random() * colorPairs.length);
    const colorPair = colorPairs[randomIndex];
    return { backgroundColor: colorPair.bgcolor, color: colorPair.color };
  };

  const truncateName = (nameArrayOrString) => {
    const nameString = Array.isArray(nameArrayOrString)
      ? nameArrayOrString.join(", ")
      : nameArrayOrString;
    return nameString.length > 20
      ? `${nameString.slice(0, 18)}...`
      : nameString;
  };

  return (
    <ListItem
      key={contact.tel ? contact.tel[0] : contact.mobile}
      disableGutters
    >
      <ListItemAvatar>
        {blobUrl ? (
          <Avatar
            alt="Contact Icon"
            src={blobUrl}
          />
        ) : (
          <Avatar sx={getColor}>
            {contact.name ? getInitials(contact.name[0]) : "NA"}
          </Avatar>
        )}
      </ListItemAvatar>
      <ListItemText
        primary={
          <Typography
            variant="subtitle1"
            fontWeight="bold"
            className={classnames({ [styles.errorNumber]: !isValidMobile })}
          >
            {contact.name ? truncateName(contact.name) : "NA"}
          </Typography>
        }
        secondary={
          <Typography
            variant="body2"
            className={classnames({ [styles.errorNumber]: !isValidMobile })}
          >
            {contact.tel
              ? contact.tel[0]
              : contact.mobile
              ? contact.mobile
              : "NA"}
          </Typography>
        }
      />
      {handleRemoveContact && (
        <IconButton aria-label="delete" onClick={handleRemoveContact}>
          <CancelIcon className={styles.errorNumber} />
        </IconButton>
      )}
    </ListItem>
  );
};

export default Contact;
