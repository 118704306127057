import axios from "axios";
import config from "../config";
const { ruleServerUrl } = config;

export const getActivationReady = (Business) => {
  return axios
    .get(
      `${ruleServerUrl}/pwa/sync-customer-offers/activation-ready?business=${Business}`
    )
    .then((response) => response.data)
    .catch((err) => {
      return err;
    });
};

export const getActivated = (Business) => {
  return axios
    .get(`${ruleServerUrl}/pwa/sync-customer-offers/activated?business=${Business}`)
    .then((response) => response.data)
    .catch((err) => {
      return err;
    });
};

export const getRedeemed = (Business) => {
  return axios
    .get(`${ruleServerUrl}/pwa/sync-customer-offers/redeemed?business=${Business}`)
    .then((response) => response.data)
    .catch((err) => {
      return err;
    });
};

// export const getVoucher = () => {
//   return axios
//     .get(`${ruleServerUrl}/vouchagram/getUserCoupons`)
//     .then((response) => response.data)
//     .catch((err) => {
//       return err;
//     });
// };

export const activateOffer = (data, Token) => {
  return axios
    .post(`${ruleServerUrl}/pwa/sync-customer-offers/activate`, {
      data,
      token: Token,
    })
    .then((response) => response.data)
    .catch((err) => {
      return err;
    });
};

// export const activateAvailableOffers = (payloadData) => {
//   return axios
//     .post(`${ruleServerUrl}/vouchagram/pullVochersAndActivate`, payloadData)
//     .then((response) => response.data)
//     .catch((err) => {
//       return err;
//     });
// };
