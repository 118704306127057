import React from "react";
import { Navigate } from "react-router-dom";
import useAuth from "../hooks/useAuth";
import ROUTE_PATHS from "../routes/routesPath";

const ProtectedRoute = (props) => {
  const authendicate = useAuth();

  if (!authendicate.token) {
    return <Navigate to={ROUTE_PATHS.ERROR} replace />;
  }
  return props.children;
};

export default ProtectedRoute;
