import {Typography} from '@mui/material'
import _, {map, without, get, has} from 'lodash'
import moment from 'moment'
import React, {useCallback, useEffect, useState} from 'react'
import loader from '../../assets/Fountain.gif'
import {ReactComponent as NoTransaction} from '../../assets/transactions.svg'
import config from '../../config'
import useAppConfig from '../../hooks/useAppConfig'
import useAuth from '../../hooks/useAuth'
import {getInvoiceSurveyResponses, getInvoiceToken} from '../../service/invoice'
import {getTransactionHistory} from '../../service/loyaltyinfo.service'
import {Permissions} from '../../shared/constants/FeaturePermissions'
import IfEmpty from '../../shared/constants/IfEmpty'
import STRING_CONSTANTS from '../../shared/constants/StringConstants'
import {
    DateBox,
    InvoiceBtn,
    TransactionCard,
} from '../../styles/styledComponent'
import {toast} from "react-hot-toast";
import { Box } from '@mui/system'
import AdditionalTextContent from './AdditionalText'

const TransactionHistory = () => {
    const [transactionHistory, setTransactionHistory] = useState([])
    const [transactionSurvey, setTransactionSurvey] = useState()
    const [returnBills, setReturnBills] = useState([])
    const [loading, setLoading] = useState(false)

    const Config = useAppConfig()
    const Business = Config?.appConfig?.selecteBussines?.name
    const isAdditionalText = _.get(Config, "appConfig.config.additionalText.ALL_PAGES.html") === "<p><br></p>";


    const decodedData = useAuth()
    const initializeSurveyFeedbacks = useCallback(async (bills) => {
        const generatedBillIds = without(map(bills, 'generatedBillId'), undefined)
        const payload = {
            tenant: _.get(decodedData, 'user.tenantId'),
            customerMobileNo: _.get(decodedData, 'user.mobile')
        }
        const data = await getInvoiceToken(payload)
        const token = Object.values(data)[0];
        const surveyResponsePayload = {
            surveyId: Number(Config?.appConfig?.config?.feedbackSurvey?.surveyId),
            generatedBillIds: generatedBillIds
        }
        const response = await getInvoiceSurveyResponses(surveyResponsePayload, token)
        const responseData = get(response, 'data', [])
        const updatedTransactionResponses = {}
        for (let userTransactionResponse of responseData) {
            const mergedResponse = get(userTransactionResponse, 'merged_response', {}) // Hidden and open form fields
            const generatedBillId = get(mergedResponse, 'generatedBillId', {}) // Hidden and open form fields
            if (!generatedBillId) {
                // If bill id is not there, skip it (Additional check - already validated in backend)
                continue;
            }
            updatedTransactionResponses[generatedBillId] = mergedResponse
        }
        setTransactionSurvey(updatedTransactionResponses);
        // eslint-disable-next-line
    }, [])
    useEffect(() => {
        setLoading(true)
        getTransactionHistory(Business).then((response) => {
            setTransactionHistory(response.bills)
            setReturnBills(response.returnedBills)
            initializeSurveyFeedbacks(response.bills)
            setLoading(false)
        })
        // eslint-disable-next-line
    }, [Business])

    const redirectToInvoice = async ({generatedBillId, invoiceType, originalInvoiceType}) => {
        try {
            // TODO Potential breach where user can construct any payload they want with our secretJwt key.
            const payload = {
                generatedBillId: generatedBillId,
                tenant: _.get(decodedData, 'user.tenantId'),
                customerMobileNo: _.get(decodedData, 'user.mobile'),
                businessUnitId: _.get(Config, 'appConfig.selecteBussines.id'),
                invoiceType: invoiceType,
                originalInvoiceType: originalInvoiceType
            }
            const data = await getInvoiceToken(payload)
            const linkTag = document.createElement('a')
            linkTag.target = '_blank'
            linkTag.href = `${config.dashboardUrl}/invoice?${Object.keys(data)[0]}=${
                Object.values(data)[0]
            }`
            return linkTag.click()
        } catch (err) {
            console.log('err', err)
        }
    }

    const redirectToInvoiceFeedback = async ({generatedBillId, invoiceType, originalInvoiceType}) => {
        try {
            const link = get(Config, 'appConfig.config.feedbackSurvey.link', undefined)
            if (!link) {
                toast.error("Feedback Survey missing")
            }
            // TODO Potential breach where user can construct any payload they want with our secretJwt key.
            const payload = {
                generatedBillId: generatedBillId,
                originalInvoiceType: originalInvoiceType,
                tenant: _.get(decodedData, 'user.tenantId'),
                customerMobileNo: _.get(decodedData, 'user.mobile'),
                businessUnitId: _.get(Config, 'appConfig.selecteBussines.id'),
                invoiceType: invoiceType
            }
            const data = await getInvoiceToken(payload)
            const linkTag = document.createElement('a')
            linkTag.target = '_blank'
            linkTag.href = `${link}${
                Object.values(data)[0]
            }`
            return linkTag.click()
        } catch (err) {
            console.log('err', err)
        }
    }

    const Bills = _.concat(transactionHistory, returnBills)

    const transactionBills = _.sortBy(Bills, (e) => {
        return e.date
    }).reverse()

    const doesHavePermission = (permission, AppConfig) =>
        _.get(AppConfig, 'config.permissions.enabled', []).includes(permission)
    const doesHaveInvoicePermission = doesHavePermission(
        Permissions.InvoiceDownload,
        Config.appConfig
    )
    const renderInvoice = ({generatedBillId, invoiceType, originalInvoiceType}) => {
        if (!doesHaveInvoicePermission) return <></>

        return (
            <InvoiceBtn
                variant="contained"
                onClick={() =>
                    redirectToInvoice({
                        generatedBillId,
                        invoiceType,
                        originalInvoiceType
                    })
                }
            >
                <p style={{display: 'flex', justifyContent: 'center'}}>
                    View Invoice
                </p>
            </InvoiceBtn>
        )
    }
    const isFeedbackSurveyEnabled = () => {
        return get(Config, 'appConfig.config.feedbackSurvey.enabled', "false").toLowerCase() === "true"
    }
    const isFeedbackAvailable = (generatedBillId) => {
        return !has(transactionSurvey, generatedBillId) && isFeedbackSurveyEnabled()
    }

    return (
        <div>
            <Typography fontWeight={700} fontSize={24} sx={{ textAlign: "center", marginTop: 4 }}>Transaction History</Typography>
            {loading ? (
                <div className="loadingGif">
                    <img src={loader} alt="Loading..."/>
                </div>
            ) : (
                transactionBills?.map((obj, index) => {
                    return (
                        <TransactionCard key={index + 1}>
                            <Typography style={{color: '#6A6A6A', marginBottom: 5}}>
                                {STRING_CONSTANTS.BILL_ID}
                                <span
                                    style={{fontWeight: 600, color: '#333333', fontSize: 14}}
                                >
                  {' '}
                                    {obj.billNo}
                </span>
                            </Typography>

                            <Typography style={{color: '#6A6A6A', marginBottom: 5}}>
                                Bill Date:
                                <span
                                    style={{fontWeight: 600, color: '#333333', fontSize: 14}}
                                >
                  {' '}
                                    {moment(`${obj?.date}`).format('DD-MM-YYYY')}
                </span>
                            </Typography>

                            <Typography style={{color: '#6A6A6A', marginBottom: 5}}>
                                Invoice Type:
                                <span
                                    style={{fontWeight: 600, color: '#333333', fontSize: 14}}
                                >
                  {' '}
                                    {obj.invoiceType === 'IN' && 'Invoice'}
                                    {obj.invoiceType === 'SR' && 'Sales Return'}
                                    {obj.invoiceType === 'VB' && 'Void Bill'}
                </span>
                            </Typography>

                            <Typography style={{color: '#6A6A6A', marginBottom: 5}}>
                                Store Name:
                                <span
                                    style={{fontWeight: 600, color: '#333333', fontSize: 14}}
                                >
                  {' '}
                                    {obj.storeName}
                </span>
                            </Typography>

                            <DateBox>
                                <Box style={{color: '#6A6A6A', display: "flex"}}>
                                    Bill Total: {' '}
                                    <Typography style={{fontWeight: 600}} color="primary">
                    INR {obj.totalAmount ? obj.totalAmount : '0'}
                  </Typography>
                                </Box>
                            </DateBox>
                            <div style={{
                                flexDirection: 'column',
                                display: 'flex',
                                gap: '10px',
                                margin: '4px 0'
                            }}>
                                {isFeedbackAvailable(obj.generatedBillId) &&
                                    (
                                        <InvoiceBtn
                                            variant="contained"
                                            onClick={() =>
                                                redirectToInvoiceFeedback(obj)
                                            }
                                        >
                                            <p style={{display: 'flex', justifyContent: 'center'}}>
                                                Give Feedback
                                            </p>
                                        </InvoiceBtn>
                                    )
                                }
                                {renderInvoice(obj)}
                            </div>
                        </TransactionCard>
                    )
                })
            )}

            {transactionBills?.length === 0 && (
                <div style={{padding: 15}}>
                    <IfEmpty
                        Icon={NoTransaction}
                        head="Sorry, no transactions found"
                        desc="Once the first bill is raised you
              will be able to see your transactions"
                    />
                </div>
            )}

            {!isAdditionalText && <AdditionalTextContent />}
        </div>
    )
}

export default TransactionHistory
