import _ from "lodash";
import React from "react";
import { useTable, usePagination } from "react-table";
import { ReactComponent as Calendar } from "../../assets/Calender.svg";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { IconButton } from "@mui/material";
import moment from "moment";
export default function PaginationTable({ columns, data, currentMonth }) {
  console.log("columns, data, currentMonth", columns, data, currentMonth);
  const isInCurrentMonth = ({ month_end }) => new Date(month_end).getMonth() === new Date().getMonth();
  const initialPage = _.findIndex(data, isInCurrentMonth);
  const {
    getTableProps,
    getTableBodyProps,
    page,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    prepareRow,
  } = useTable(
    {
      columns,
      data,
      initialState: { pageSize: 1, pageIndex: initialPage },
    },
    usePagination
  );

  console.log('page', page);

  function earnStyle(invoiceType, earnedPoints) {
    if (invoiceType === "SR") return "burned";
    else if (invoiceType === "VB" && earnedPoints !== 0) return "voidBill";
    return "earned";
  }

  function burnStyle(invoiceType, burnedPoints) {
    if (invoiceType === "SR") return "earned";
    else if (invoiceType === "VB" && burnedPoints !== 0) return "voidBill";
    return "burned";
  }

  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-around",
          alignItems: "center",
          padding: 10,
          backgroundColor: "#7771FF",
        }}
      >
        <IconButton onClick={() => previousPage()} disabled={!canPreviousPage}>
          <ArrowBackIosNewIcon style={{ color: "white" }} />
        </IconButton>
        <div style={{ textAlign: "center", color: "white" }}>
          {page.map((row) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell) => {
                  let months = new Date(`${cell.row.original.month_end}`);
                  return (
                    <td {...cell.getCellProps()}>
                      <div style={{ display: "flex" }}>
                        <Calendar
                          style={{ width: 30, height: 25, marginRight: 7 }}
                        />
                        <span style={{ fontWeight: 700, fontSize: 18 }}>
                          {months.toLocaleDateString("default", {
                            month: "short",
                          })}
                          {`, ${months.getFullYear()}`}
                        </span>
                      </div>
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </div>
        <IconButton onClick={() => nextPage()} disabled={!canNextPage}>
          <ArrowForwardIosIcon style={{ color: "white" }} />
        </IconButton>
      </div>

      <table
        id="myTable"
        {...getTableProps()}
        style={{
          textAlign: "center",
          width: "100%",
          borderCollapse: "collapse",
        }}
      >
        <tbody {...getTableBodyProps()}>
          {page.map((row, i) => {
            prepareRow(row);
            return (
              <>
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    let months = new Date(`${cell.row.original.month_end}`);
                    const presentAndFutureMonth = moment(
                      moment(months).endOf("day")
                    ).isAfter(moment());
                    return (
                      <>
                        <td {...cell.getCellProps()} style={{ width: "36%" }}>
                          <div
                            style={{ display: "flex", flexDirection: "column" }}
                          >
                            <span>Opening</span>
                            <span style={{ color: "#7771FF", fontWeight: 500 }}>
                              {cell.row.original.openingPoints.length > 0
                                ? cell.row.original.openingPoints.map((val) => {
                                    return Math.floor(val.sum);
                                  })
                                : 0}
                            </span>
                          </div>
                        </td>

                        <td {...cell.getCellProps()}>
                          <div
                            style={{ display: "flex", flexDirection: "column" }}
                          >
                            <span>Expiry</span>
                            <span style={{ color: "#7771FF", fontWeight: 500 }}>
                              {presentAndFutureMonth
                                ? 0
                                : cell.row.original.expiryByMonth.length > 0
                                ? cell.row.original.expiryByMonth.map(
                                    (points) => {
                                      return Math.floor(points.sum);
                                    }
                                  )
                                : 0}
                            </span>
                          </div>
                        </td>

                        <td {...cell.getCellProps()}>
                          <div
                            style={{ display: "flex", flexDirection: "column" }}
                          >
                            <span>Closing</span>
                            <span style={{ color: "#7771FF", fontWeight: 500 }}>
                              {cell.row.original.sum.length > 0
                                ?  Math.floor(cell.row.original.sum)
                                : 0}
                            </span>
                          </div>
                        </td>
                      </>
                    );
                  })}
                </tr>

                <tr
                  style={{
                    backgroundColor: "#F1F1F1",
                    fontWeight: 600,
                  }}
                >
                  <td>Bill Id</td>
                  <td>Earn</td>
                  <td>Reedemed</td>
                </tr>

                {row.cells.map((cell) => {
                  return cell.row.original.billDetails.length > 0 ? (
                    cell.row.original.billDetails.map((bill, index) => {
                      return (
                        <>
                          <tr key={index} style={{ textAlign: "center" }}>
                            <td style={{ fontSize: 13 }}>
                              {bill.casa_bill_id.slice(0, 10)}....
                            </td>
                            <td
                              className={earnStyle(
                                bill.invoiceType,
                                Math.floor(bill.earned)
                              )}
                            >
                              {Math.floor(bill.earned) === 0 ||
                              Math.floor(bill.earned) < 0
                                ? Math.floor(bill.earned)
                                : `+${Math.floor(bill.earned)}`}
                            </td>

                            <td
                              className={burnStyle(
                                bill.invoiceType,
                                Math.floor(bill.earned)
                              )}
                            >
                              {Math.floor(bill.burned) === 0 ||
                              Math.floor(bill.burned) < 0
                                ? Math.floor(bill.burned)
                                : `-${Math.floor(bill.burned)}`}
                            </td>
                          </tr>
                        </>
                      );
                    })
                  ) : (
                    <tr>
                      <td>-</td>
                      <td>-</td>
                      <td>-</td>
                    </tr>
                  );
                })}

                <tr
                  style={{
                    textAlign: "center",
                    border: "1px solid #f1f1f1",
                    backgroundColor: "#7771FF",
                    color: "white",
                  }}
                >
                  <td style={{ fontWeight: 600 }}>Current Total</td>
                  {row.cells.map((cell) => {
                    return (
                      <>
                        <td style={{ fontWeight: 600 }}>
                          {_.floor(
                            _.sumBy(cell.row.original.billDetails, (element) =>
                              _.toNumber(element.earned)
                            )
                          )}
                        </td>
                        <td style={{ fontWeight: 600 }}>
                          {_.floor(
                            _.sumBy(cell.row.original.billDetails, (element) =>
                              _.toNumber(element.burned)
                            )
                          )}
                        </td>
                      </>
                    );
                  })}
                </tr>
              </>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}
