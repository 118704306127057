import { Box, Container } from "@mui/material";
import { useCallback, useState } from "react";
import Brand from "../../../../shared/components/Brand";
import styles from "../../../../shared/constants/style";
import { AppBar } from "../../../../styles/styledComponent";
import MenuButton from "../MenuButton";
import NavMenu from "../NavMenu";

const Navbar = ({ menu }) => {
  const [isNavActive, setIsNavActive] = useState(false);

  const toggleNavActive = () => {
    setIsNavActive((isNavActive) => !isNavActive);
  };

  const handleNavClose = useCallback(() => setIsNavActive(false), []);

  return (
    <AppBar position="sticky" elevation={0}>
      <Container className="navContainer" maxWidth={styles.APP_WIDTH}>
        <Box className="navCol1">
          <MenuButton className="menuBtn" onClick={toggleNavActive} />
          <Brand />
        </Box>
        <NavMenu links={menu} open={isNavActive} onClose={handleNavClose} />
      </Container>
    </AppBar>
  );
};

export default Navbar;
