import styled from "@emotion/styled";
import { Button, TextField, Box } from "@mui/material";
const isLowestMobileWidth = window.innerWidth < 360;

export const StyledBox = styled(Box)(() => ({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: "20px",
    margin: "20px 0",
    width: "100%",
    justifyContent: "center"
}))

export const StyledButton = styled(Button)(() => ({
    borderRadius: "4px",
    width: isLowestMobileWidth ? "260px" : "340px",
    fontWeight: "bold",
    fontSize: "16px"
}))

export const StyledTextField = styled(TextField)(() => ({
    width: isLowestMobileWidth ? "260px" : "340px",
    fontSize: "18px",
    color: "rgba(51, 51, 51, 1)",
    fontWeight: "bold"
}))

export const otpContainerStyles = () => {
    return {
        width: "100%",
        display: "flex",
        justifyContent: "space-between",
    }
}

export const otpInputStyes = () => {
    return {
        height: isLowestMobileWidth ? "40px" : "50px",
        width: isLowestMobileWidth ? "40px" : "50px",
        border: "1px solid rgba(205, 209, 220, 1)",
        borderRadius: "4px",
        fontSize: "18px"
    }
}