import { Container } from "@mui/material";
import { useEffect, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import useAppConfig from "../hooks/useAppConfig";
import { INITIAL_ROUTE } from "../routes/const";
import styles from "../shared/constants/style";
import Navbar from "./Navbar";
import {get} from "lodash";

const Layout = () => {
  const [menu, setMenu] = useState();
  const [intialModule, setIntialModule] = useState("");
  const navigate = useNavigate();
  const pwaConfig = useAppConfig();
  useEffect(() => {
    let menu = pwaConfig?.appConfig?.config?.menu;
    const flipbook = get(pwaConfig, 'appConfig.config.flipbook', {})
    if (get(flipbook, 'enabled') === 'true'){
      const link = get(flipbook, 'link')
      if (link){

        menu = {FLIPBOOK: {module: 'FLIPBOOK', name: 'Curated For You', path: ''}, ...menu}
      }
    }
    setMenu(menu);
    //console.log(pwaConfig?.appConfig?.config?.menu)
    setIntialModule(pwaConfig?.appConfig?.config?.initialModule);
    if (INITIAL_ROUTE[intialModule]) navigate(INITIAL_ROUTE[intialModule]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [intialModule, pwaConfig]);
  return (
    <>
      <Navbar menu={menu} />
      <Container maxWidth={styles.APP_WIDTH} style={{ paddingInline: 0 }}>
        <Outlet />
      </Container>
    </>
  );
};

export default Layout;
