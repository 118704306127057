import {
    Alert,
    Typography,
    Snackbar
} from '@mui/material'
function ErrorToast({severity, message, handleCloseErrorToast}) {
  return (
    <Snackbar
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
      open="true"
      autoHideDuration={6000}
      onClose={handleCloseErrorToast}
    >
      <Alert
        onClose={handleCloseErrorToast}
        severity={severity}
        variant="filled"
        sx={{ width: "100%" }}
      >
        <Typography fontWeight="bold">
          {message}
        </Typography>
      </Alert>
    </Snackbar>
  );
}

export default ErrorToast