import _ from "lodash"

export const getParamsFromUrl = (url, key) => {
  const params = new URLSearchParams(url)
  if (!Array.isArray(key) && params.has(key)) {
    return params.get(key)
  }
  const selectedParams = key.reduce((acc, cur) => {
    if (params.has(cur)) {
      acc[cur] = params.get(cur)
    }
    return acc
  }, {})
  return pickNonEmpty(selectedParams)
}

const pickNonEmpty = (obj) => _.omitBy(obj, _.isNil)

export const getFormatErrors = (e) =>{
  
  return _.get(e, "response.data.errors")
    ? _.map(_.get(e, "response.data.errors", []), "message")
    : _.get(e, "response.data.error", "Something went wrong!!")
}

