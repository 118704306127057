import axios from "axios";
import config from "../config";
const { ruleServerUrl } = config;

export function updateMobileNumber(data, businessId) {
  return fetch(`${ruleServerUrl}/users/migrate-customers/${businessId}`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(data),
    headers: {
      "Content-type": "application/json",
    },
  })
    .then((r) => {
      return r.json();
    })
    .catch((err) => {
      return err;
    });
}

// export const updateMobileNumber = (data, businessId) => {
//   return axios
//     .post(`${ruleServerUrl}/users/migrate-customers/${businessId}`, data)
//     .then((response) => response.data)
//     .catch((err) => {
//       return err;
//     });
// };

export const updateProfile = (data) => {
  return axios
    .post(`${ruleServerUrl}/user-upsert`, data)
    .then((response) => response.data)
    .catch((err) => {
      return err;
    });
};

export const getProfile = () => {
  return axios
    .get(`${ruleServerUrl}/pwa/profile`)
    .then((response) => response.data)
    .catch((err) => {
      return err;
    });
};
