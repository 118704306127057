import "./styles/index.css";
import "./styles/index.scss";
import { CssBaseline } from "@mui/material";
import { Suspense, useEffect, useLayoutEffect } from "react";
import { Route, Routes } from "react-router-dom";
import useAppConfig from "./hooks/useAppConfig";
import useAppTheme from "./hooks/useAppTheme";
import Layout from "./Layout";
import ROUTES from "./routes";
import { Loader } from "./shared/components/UI";
import DynamicForm from "./components/DynamicForm";
import ROUTE_PATHS from "./routes/routesPath";
import ProtectedRoute from "./components/ProtectedRoute";
import AuthenticateFail from "./components/AuthenticateFail";
import PushNotification from "./components/PushNotification/PushNotification";
import { handleDocumentChanges } from "./manifest";
import { get } from "lodash";
import useAuth from "./hooks/useAuth";
import CustomerVerify from "./components/Customerverify/CustomerVerify";
import Redirect from "./routes/Redirect";
function App() {
  // eslint-disable-next-line
  const { theme, setTheme } = useAppTheme();
  const pwaConfig = useAppConfig();
  const authUser = useAuth();

  const routeMapping = (path, component, nestedRoutes, exact) => {
    let Component = component;
    return (
      <Route
        key={path}
        path={path}
        element={
          <ProtectedRoute>
            <Component />
          </ProtectedRoute>
        }
        exact={exact}
      >
        {nestedRoutes &&
          nestedRoutes.map(({ path, component, nestedRoutes }) => {
            return routeMapping(path, component, nestedRoutes);
          })}
      </Route>
    );
  };

  useLayoutEffect(() => {
    if (pwaConfig?.appConfig?.config) {
      const { typography, palette } = pwaConfig?.appConfig?.config;
      setTheme({
        typography: { fontFamily: typography?.fontFamily },
        palette: {
          primary: {
            main: palette?.primary,
          },
          secondary: { main: palette?.sideNav.background },
          text: { secondary: palette?.sideNav.textColor },
        },
      });
      handleDocumentChanges({
        tenant: get(authUser, "user.tenantId"),
        logo: get(pwaConfig, "appConfig.config.logo"),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pwaConfig?.appConfig?.config]);

  useEffect(() => {
    console.log("V-qa-updated-code v1.1")
  }, []);

  return (
    <Suspense fallback={<Loader />}>
      <CssBaseline />
      <Routes>
        <Route
          path="/"
          element={
            <ProtectedRoute>
              <Layout />
            </ProtectedRoute>
          }
        >
          {ROUTES.map(({ path, component, nestedRoutes, exact }) =>
            routeMapping(path, component, nestedRoutes, exact)
          )}
        </Route>
        <Route path={ROUTE_PATHS.ERROR} element={<AuthenticateFail />} />
        <Route path={ROUTE_PATHS.REGISTER} element={ <Redirect /> } />
        <Route path={ROUTE_PATHS.SIGN_UP} element={ <DynamicForm /> } />
        <Route path={ROUTE_PATHS.QR_REDIRECT} element={<CustomerVerify />} />
        <Route
          path={ROUTE_PATHS.SIGN_UP_SHORTENED}
          element={<CustomerVerify />}
        />
      </Routes>
      <PushNotification />
    </Suspense>
  );
}

export default App;
