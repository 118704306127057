import React, { useState } from "react";
import { Box, Typography, TextField, Button, Alert } from "@mui/material";
import { useForm } from "react-hook-form";
import { ReferContainer } from "../../styles/styledComponent";
import STRING_CONSTANTS from "../../shared/constants/StringConstants";
import InviteDialog from "./InviteDialog";

import { sendLinkV2 } from "../../service/referFriend.service";
import ErrorToast from "./ErrorToast";

function ReferralForm() {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [apiError, setApiError] = useState(false)
  const [referrals, setReferrals] = useState({
    validReferrals: [],
    alreadyReferredUsers: [],
    existingUsers: [],
    invalidUsers: [],
  });

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isValid },
  } = useForm({
    defaultValues: {
      mobile: "",
      name: "",
    },
    mode: "onChange",
  });

  const handleOpenDialog = () => {
    setDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
    reset();
  };

  const handleCloseErrorToast = () => {
    setApiError(false)
  }
 
  const onSubmit = async (data) => {
    console.log(data);
    try {
      const responseData = await sendLinkV2({ referrals: [data] });
      console.log(responseData);
      setReferrals(responseData);
    } catch (error) {
      setApiError(true);
    } 
    handleOpenDialog();
  };

  const handleResetForm = () => {
    reset();
  };

  return (
    <ReferContainer>
      <Alert
        severity="warning"
        sx={{ justifyContent: "center", width: "100%" }}
      >
        Contact Picker API is not Supported
      </Alert>

      <Typography variant="h6" fontWeight="bold">
        Don't worry!
      </Typography>
      <Typography fontWeight="bold">
        You can manually enter your friend's name and number
      </Typography>
      <Box width="100%">
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box display="flex" flexDirection="column" gap="0.5rem">
            <TextField
              label="Friend's Mobile Number"
              type="tel"
              required
              inputProps={{ maxLength: 10 }}
              {...register("mobile", {
                required: "Mobile number is required",
                pattern: {
                  value: /^[6-9]\d{9}$/,
                  message: "Please enter a valid 10-digit mobile number",
                },
              })}
              fullWidth
              error={!!errors.mobile}
              helperText={errors.mobile ? errors.mobile.message : ""}
            />
            <TextField
              label="Friend's Name"
              type="text"
              {...register("name", {
                pattern: {
                  value: /^[a-zA-Z]+[a-zA-Z\s.]+$/, 
                  message: "Only valid names are allowed"
                }
              })}
              error={!!errors.name}
              helperText={errors.name ? errors.name.message : ""}
              fullWidth
            />
            <Button
              type="submit"
              variant="contained"
              color="primary"
              fullWidth
              disabled={!isValid}
            >
              <Typography variant="subtitle1" fontWeight={600}>
                {STRING_CONSTANTS.INVITE_FRIENDS}
              </Typography>
            </Button>
          </Box>
        </form>
      </Box>

      <InviteDialog
        open={dialogOpen}
        onClose={handleCloseDialog}
        onAnotherInvite={handleResetForm}
        referrals={referrals}
      />

      {apiError && (
        <ErrorToast
          message="Failed to Send Invite. Please try again later."
          handleCloseErrorToast={handleCloseErrorToast}
        />
      )}
    </ReferContainer>
  );
}

export default ReferralForm;
