import { Container } from "@mui/material";
import React, { useEffect, useState } from "react";
import HeroBanner from "../../Layout/HeroBanner";
import Footer from "../../Layout/Footer";
import useAppConfig from "../../hooks/useAppConfig";
import TransactionHistory from "../LoyaltyInfo/TransactionHistory";

const History = () => {
  const [bannerImg, setBannerImg] = useState("");
  const [footerImg, setFooterImg] = useState("");
  const pwaConfig = useAppConfig();

  useEffect(() => {
    if (pwaConfig.appConfig.config.notForAllPage === "false") {
      setBannerImg(pwaConfig.appConfig.config.modules.FOR_ALL_PAGE.headerImage);
      setFooterImg(pwaConfig.appConfig.config.modules.FOR_ALL_PAGE.footerImage);
    } else {
      setBannerImg(
        pwaConfig?.appConfig?.config?.modules?.TRANSACTION_HISTORY?.headerImage
          ? pwaConfig?.appConfig?.config?.modules?.TRANSACTION_HISTORY
              ?.headerImage
          : pwaConfig?.appConfig?.config?.modules?.FOR_ALL_PAGE?.headerImage
      );
      setFooterImg(
        pwaConfig?.appConfig?.config?.modules?.TRANSACTION_HISTORY?.footerImage
          ? pwaConfig?.appConfig?.config?.modules?.TRANSACTION_HISTORY
              ?.footerImage
          : pwaConfig?.appConfig?.config?.modules?.FOR_ALL_PAGE?.footerImage
      );
    }
  }, [pwaConfig]);

  return (
    <>
      <Container>
        <HeroBanner src={bannerImg} />
        <TransactionHistory />
        <Footer img={footerImg} />
      </Container>
    </>
  );
};

export default History;
