import { Box } from "@mui/material";

const Banner = ({ className, src, alt }) => {
  return (
    <Box className={className} sx={{ display: "flex" }}>
      <img src={src} alt={alt} style={{ width: "100%", aspectRatio: 2, objectFit: "cover" }} />
    </Box>
  );
};

export default Banner;
