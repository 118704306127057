import { Card } from "@mui/material";
import React from "react";
import { ErrorDetails, ErrorHead } from "../../styles/styledComponent";

const IfEmpty = ({ Icon, head, desc }) => {
  return (
    <div>
      <Card className="card">
        <Icon className="iconmargin" />
        <ErrorHead>{head}</ErrorHead>
        <ErrorDetails>{desc}</ErrorDetails>
      </Card>
    </div>
  );
};

export default IfEmpty;
