import _ from "lodash";

const classnames = (...classes) => {
  return _.reduce(
    classes,
    (acc, val) => {
      if (_.isString(val)) acc += ` ${val}`;
      if (_.isArray(val)) acc += ` ${_.compact(val)}`;
      if (_.isObject(val))
        acc += ` ${_.keys(_.pickBy(val, _.identity)).join(" ")}`;
      return acc;
    },
    ""
  );
};

export default classnames;
