import { ArcElement, Chart as ChartJS, Legend, Tooltip } from "chart.js";
import { useMemo } from "react";
import { Doughnut } from "react-chartjs-2";

ChartJS.register(ArcElement, Tooltip, Legend);

const LoyaltyPointsChart = ({ earned, redeemed, expired }) => {
  const chartData = useMemo(
    () => ({
      labels: [
        `Active (${earned})`,
        `Redeemed (${redeemed})`,
        `Expired (${expired})`,
      ],
      datasets: [
        {
          data: [earned, redeemed, expired],
          backgroundColor: [
            "rgb(97, 205, 187)",
            "rgb(241, 225, 91)",
            "rgba(0, 0, 0, 0.2)",
          ],
        },
      ],
    }),
    [earned, redeemed, expired]
  );

  return <Doughnut data={chartData} />;
};
export default LoyaltyPointsChart;
