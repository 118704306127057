import React from "react";
import { ReactComponent as Authentication } from "../assets/Authentication_failed.svg";
import {
  ErrorContainer,
  ErrorDetails,
  ErrorHead,
} from "../styles/styledComponent";

const AuthenticateFail = () => {
  return (
    <ErrorContainer>
      <Authentication />
      <ErrorHead>Authentication Failed!</ErrorHead>
      <ErrorDetails>
        Token unavailable. Please login into PWA application from your Mobile
        device to access the link.
      </ErrorDetails>
    </ErrorContainer>
  );
};

export default AuthenticateFail;
