import { Box } from "@mui/material";
import Banner from "../Banner";

const Footer = ({ img }) => {
  return (
    <Box componenet="footer">
      <Banner src={img} alt="" />
    </Box>
  );
};

export default Footer;
