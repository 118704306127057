const STRING_CONSTANTS = {
  TOKEN_PARAM_KEY: "data",
  COOKIE_NAME: "pwaToken",
  AVAILABLE_OFFERS: "Available Offers",
  REEDEMED_OFFERS: "Reedemed Offers",
  EMPTY: "Oops... It's empty in here!",
  NO_OFFERS_FOUND: "No Offers available for you currently",
  FILTER: "Filter",
  ONLY_FOR_YOU: "Only for You",
  GENERIC_OFFER: "Generic Offer",
  OFFER_TYPE: " Based",
  OFFER_CODE: "Offer Code: ",
  REFER_A_FRIEND: "REFER A FRIEND",
  INVITE: "Invite",
  INVITE_FRIENDS: "Invite Friends",
  REFERED_FRIEND: "REFERED FRIEND",
  LOYALTY_POINTS: "Active Points",
  LOYALTY_LEVEL: "Loyalty Tier",
  POINTS_EXPIRED: "Points expiring by",
  DAYS: "Days",
  BILL_ID: "Bill No:",
  EMAIL: "Email:  ",
  PHONE_NUMBER: "Phone Number:  ",
  VOUCHER_NO: "Voucher No: ",
  VOUVHER_PIN: "Voucher Pin",
  VALID_TILL: "Valid Till ",
  ACTIVE: "Active",
  NOT_AVAILABLE: "Not Available",
  BRAND_LOGO: "Brand Logo",
  ACTIVATE: "Activate",
  SUBMIT: "Submit",
  OK: "OK",
  APOLOGY: "Apologies! ",
  CONTENT: "Something went wrong!",
  AWESOME: "Awesome!",
  CONTENT_1: "You have Activated 1 voucher",
  CONTENT_2:
    "Show this when you are doing next purchase and enjoy shopping benefits",
};

export default STRING_CONSTANTS;
