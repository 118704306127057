import { Container } from "@mui/material";
import { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import useAppConfig from "../../hooks/useAppConfig";
import Footer from "../../Layout/Footer";
import HeroBanner from "../../Layout/HeroBanner";
import HomeHeader from "../Offers/HomeHeader";
import AdditionalTextContent from "../LoyaltyInfo/AdditionalText";
import _ from "lodash";

const Home = () => {
  const [bannerImg, setBannerImg] = useState();
  const [footerImg, setFooterImg] = useState();
  const pwaConfig = useAppConfig();
  const isAdditionalText = _.get(pwaConfig, "appConfig.config.additionalText.ALL_PAGES.html") === "<p><br></p>";

  useEffect(() => {
    if (pwaConfig.appConfig.config.notForAllPage === "false") {
      setBannerImg(pwaConfig.appConfig.config.modules.FOR_ALL_PAGE.headerImage);
      setFooterImg(pwaConfig.appConfig.config.modules.FOR_ALL_PAGE.footerImage);
    } else {
      setBannerImg(
        pwaConfig?.appConfig?.config?.modules?.OFFER_FOR_YOU?.headerImage
          ? pwaConfig?.appConfig?.config?.modules?.OFFER_FOR_YOU?.headerImage
          : pwaConfig?.appConfig?.config?.modules?.FOR_ALL_PAGE?.headerImage
      );
      setFooterImg(
        pwaConfig?.appConfig?.config?.modules?.OFFER_FOR_YOU?.footerImage
          ? pwaConfig?.appConfig?.config?.modules?.OFFER_FOR_YOU?.footerImage
          : pwaConfig?.appConfig?.config?.modules?.FOR_ALL_PAGE?.footerImage
      );
    }
  }, [pwaConfig]);

  return (
    <>
      <Container sx={{ py: 3 }}>
        <HeroBanner src={bannerImg} />
        <HomeHeader />
        <Outlet />
        {!isAdditionalText && <AdditionalTextContent />}
        <Footer img={footerImg} />
      </Container>
    </>
  );
};

export default Home;
