import { capitalize } from "lodash";
import config from "./config";
import { setAndGetTokenFromCookie } from "./shared/utils/getToken";
import { DefaultFavIcon } from "./components/DynamicForm/constants";

const { ruleServerUrl } = config;

export const initialize = () => {
  const authToken = setAndGetTokenFromCookie();

  if (!authToken) return false;

  const manifestElement = document.createElement("link");
  manifestElement.setAttribute("rel", "manifest");
  manifestElement.setAttribute(
    "href",
    `${ruleServerUrl}/pwa/manifest.json?token=${authToken}`
  );
  document.head.appendChild(manifestElement);
  return true;
};

export const handleDocumentChanges = ({ tenant, logo }) => {
  document.title = capitalize(tenant || "casa")
  const manifestElement = document.createElement("link")
  manifestElement.setAttribute("rel", "icon")
  manifestElement.setAttribute("href", logo || DefaultFavIcon)
  return document.head.appendChild(manifestElement)
}